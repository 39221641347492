<template>
    <div>
      <div class="about">
        <v-card elevation="0" color="#AC8250" class="white--text pa-6 text-body-1">
          <p>
            {{ $t('AppBar.SubMenu.TokenDistribution') }}
          </p>
          <p>
            <span>{{ $t('Page.Dist.Market') }}：</span>70%
          </p>
          <p>
            <span>
              {{ $t('Page.Dist.MarketDesc1') }}
            </span>
          </p>
          <br/>
          <p>
            {{ $t('Page.Dist.Brand') }}：20%<br />
          </p>
          <p>
            {{ $t('Page.Dist.BrandDesc') }}
          </p>
          <br />
          <p>
          {{ $t('Page.Dist.UserAward') }}：10%<br />
          </p>
          <p>
          {{ $t('Page.Dist.UserAwardDesc') }}
        </p>
        </v-card>
        
        <div class="text-h5 font-weight-bold mt-10">SCT{{ $t('AppBar.SubMenu.TokenDistribution') }}</div>
        <v-card elevation="0" class="pa-6 mt-6">
          <div class="mx-auto" id="chart" style="height:500px;"></div>
        </v-card>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue';

import * as echarts from 'echarts';

export default {
  components: {
    Footer
  },
  mounted(){
    this.drawChart();
  },
  methods: {
    drawChart() {
      var chartDom = document.getElementById('chart');
      var myChart = echarts.init(chartDom);
      var option;

      var type1 = this.$i18n.t('Page.Dist.ChartS1');
      var type2 = this.$i18n.t('Page.Dist.ChartS2');
      var type3 = this.$i18n.t('Page.Dist.ChartS3');

      option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}:  {c}%'
        },
        legend: {
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 70, name: type1 },
              { value: 20, name: type2 },
              { value: 10, name: type3 }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);

    }
  }
}
</script>

<style>
  .about {
    min-height: calc(100vh - 289px);
    padding: 65px 120px;
    background: #F8F4F0;
  }

  @media screen and (max-width: 960px) {
    .about {
      padding: 30px 24px;
    }
  }
</style>
  